/**
 * Displays an alert message on the web page and hides it after a short duration.
 *
 * @param {string} message - The message to be displayed in the alert.
 */
export const showAlert = function (message, alertTime = 2500) {
  const alertElement = document.getElementById("alert");
  alertElement.textContent = "";
  alertElement.classList.remove("hidden");
  alertElement.textContent = message;
  alertElement.style.pointerEvents = "none";
  // alertElement.style.color = "red";

  alertElement.innerHTML = message.replace(/\n/g, "<br>");
  // Hide alert after 2.5 seconds
  setTimeout(function () {
    if (alertElement.parentElement) {
      alertElement.classList.add("hidden");
    }
  }, alertTime);
};
